const baseDesConfig = {
  title: "基本信息",
  column: 2,
  nameList: [
    {
      label: "公司名称",
      span: 1,
      dataIndex: "format_name",
    },
    {
      label: "法定代表人",
      span: 1,
      dataIndex: "oper_name",
    },
    {
      label: "统一社会信用代码",
      span: 1,
      dataIndex: "credit_no",
    },
    {
      label: "经营状态",
      span: 1,
      dataIndex: "new_status",
    },
    {
      label: "所属行业",
      span: 1,
      dataIndex: "domains",
    },
    {
      label: "成立日期",
      span: 1,
      dataIndex: "start_date",
    },
    {
      label: "企业类型",
      span: 1,
      dataIndex: "econ_kind",
    },
    {
      label: "营业期限",
      span: 1,
      dataIndex: "term_end",
    },
    {
      label: "登记机关",
      span: 1,
      dataIndex: "belong_org",
    },
    {
      label: "核准日期",
      span: 1,
      dataIndex: "check_date",
    },
    {
      label: "企业荣誉",
      span: 2,
      dataIndex: "tech_ent",
    },
    {
      label: "注册地址",
      span: 2,
      dataIndex: "address",
    },
    {
      label: "经营范围",
      span: 2,
      dataIndex: "scope",
    },
    // 将联系电话、邮箱、地址合并到基本信息中
    {
      label: "联系电话",
      dataIndex: "telephone",
      span: 1,
    },
    {
      label: "邮箱",
      dataIndex: "email",
      span: 1,
    },
    {
      label: "地址",
      dataIndex: "contactAddress",
      span: 2,
    },
  ],
};
export default baseDesConfig;
